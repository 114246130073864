import { merge } from '@ecp/utils/common';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentServicingShared } from '../../../../../../libs/env/src/index';
import { env as baseEnv } from '../env';

export const env: EnvironmentServicingShared = merge({}, baseEnv, {
  static: {
    partnerId: '660',
    title: 'Lemonade Insurance',
  },
  ccpaUrl: 'https://go.homesite.com/cpa/',
  hotjarEnabled: true,
  chatEnabled: false,
});
